import React, { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import PrivateRoute from 'components/routing/PrivateRoute';

/**
 * ympshipyard: Build interface for shipyard
 * ympolain-165 - ISM interface for forms
 * ympolain-177 - Edit profile for shipyard users
 * ymploki-197: Build interface for Spare parts
 * great-galing-sc-255: Show equipment history for archived equipment
 * loki-ribero-sc-277: new logsbook setup
 * maco-magsael-sc-345: ISM - autosave implementation
 * olain-tarcina-sc-346: Print preview template
 * maco-magsael-sc-407 - Terms and conditions - add terms in webpage footer
 * maco-magsael-sc-447: Crew - crew list printing with email
 * olain-tarcina-sc-451: sales module
 * maco-magsael-sc-458: Calendars - develop calendar module
 * management-module: Build interface for management
 * maco-magsael-sc-485: Equipment - Add annotation for equipment
 * olain-tarcina-sc-543: client details feature
 * maco-magsael-sc-551: Accounting - Create Invoice generator
  maco-magsael-sc-612: Equipment - create equipment details page
 * new-shipyard
 * maco-magsael-sc-616: Reports - Email reports
 * maco-magsael-sc-670: Sales - add fullcalendar
 * olain-tarcina-sc-664: Hours of Rest - print revamp
 * maco-magsael-sc-677: YMP Lite - Interface for lite equipment and jobs
 * maco-magsael-sc-689: Charter - create charter interface
 * maco-magsael-sc-691: Sales - Create TYS Sales module
 * maco-magsael-sc-692: Payroll - Create payroll module
 * olain-tarcina-sc-721: Vessel Docs - add checklist function to documents
 */

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/jobdetails/:id`}
          component={lazy(() => import(`./jobdetails/JobDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/logbook`}
          component={lazy(() => import(`./logbook/Logbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/logbookdetails/:id`}
          component={lazy(() => import(`./logbookdetails/LogbookDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/equipment`}
          component={lazy(() => import(`./equipment/Equipment`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/manageequipment`}
          component={lazy(() => import(`./manageequipment/ManageEquipment`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/inventory`}
          component={lazy(() => import(`./inventory/Inventory`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/reports`}
          component={lazy(() => import(`./reports/Reports`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/watchkeeping`}
          component={lazy(() => import(`./watchkeeping/Watchkeeping`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/employees`}
          component={lazy(() => import(`./employees/Employees`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/profiledetails/:id`}
          component={lazy(() => import(`./profiledetails/ProfileDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/guests`}
          component={lazy(() => import(`./guests/Guests`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/guestdetails/:id`}
          component={lazy(() => import(`./guestdetails/Guestdetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/vesseldocs`}
          component={lazy(() => import(`./vesseldocs/Vesseldocs`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/ism`}
          component={lazy(() => import(`./ism/Ism`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/supplier`}
          component={lazy(() => import(`./supplier/Supplier`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/spareparts`}
          component={lazy(() => import(`./spareparts/Spareparts`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/superadmin`}
          component={lazy(() => import(`./superadmin/Superadmin`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/help`}
          component={lazy(() => import(`./help/Help`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/editprofile/:id`}
          component={lazy(() => import(`./editprofile/EditProfile`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/hoursofrest`}
          component={lazy(() => import(`./hoursofrest/HoursOfRest`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/onboard`}
          component={lazy(() => import(`./onboard/Onboard`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/myvessel`}
          component={lazy(() => import(`./myvessel/MyVessel`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/vacationleaves`}
          component={lazy(() => import(`./vacationleaves/VacationLeaves`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/portlogbook`}
          component={lazy(() => import(`./portlogbook/PortLogbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/sealogbook`}
          component={lazy(() => import(`./sealogbook/SeaLogbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/portbridgelogbook`}
          component={lazy(() =>
            import(`./portbridgelogbook/PortBridgeLogbook`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/seabridgelogbook`}
          component={lazy(() => import(`./seabridgelogbook/SeaBridgeLogbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/crewlist`}
          component={lazy(() => import(`./crewlist/CrewList`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/superadmin/:id`}
          component={lazy(() => import(`./boatdetails/BoatDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/adminequipment`}
          component={lazy(() => import(`./adminequipment/AdminEquipment`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/adminjobs`}
          component={lazy(() => import(`./adminjobs/AdminJobs`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/adminjobs/:id`}
          component={lazy(() => import(`./adminjobdetails/Adminjobdetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/adminusers`}
          component={lazy(() => import(`./adminusers/AdminUsers`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/adminforms`}
          component={lazy(() => import(`./adminforms/AdminForms`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/adminforms/:id`}
          component={lazy(() => import(`./formdetails/FormDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/watchkeeping/:id`}
          component={lazy(() => import(`./watchdetails/WatchDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/calendar/:id`}
          component={lazy(() => import(`./watchcalendar/WatchCalendar`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/calendar`}
          // modification start - 08.04.2023 - maco-magsael-sc-458
          component={lazy(() => import(`./newcalendar/NewCalendar`))}
          // modification end - 08.04.2023 - maco-magsael-sc-458
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/watchkeepinglogs`}
          component={lazy(() => import(`./watchkeepinglogs/WatchkeepingLogs`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/manageleaves`}
          component={lazy(() => import(`./manageleaves/ManageLeaves`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/ismform/:id`}
          component={lazy(() => import(`./ismform/IsmForm`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/formarchives`}
          component={lazy(() => import(`./formarchives/FormArchives`))}
        />
        <PrivateRoute
          exact
          // modification start - 02.23.2023 - maco-magsael-sc-345
          path={`${APP_PREFIX_PATH}/ismdraft/:id`}
          // modification end - 02.23.2023 - maco-magsael-sc-345
          component={lazy(() => import(`./ismdraft/IsmDraft`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/formarchives/:id`}
          component={lazy(() => import(`./ismformdetails/IsmFormDetails`))}
        />
        {/* Modification start - 08.23.2022 - ympshipyard */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/shipyard`}
          component={lazy(() => import(`./shipyard/Shipyard`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/teams`}
          component={lazy(() => import(`./teams/Teams`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/records`}
          component={lazy(() => import(`./records/Records`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/decks`}
          component={lazy(() => import(`./decks/Decks`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./users/Users`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/userdetails/:id`}
          component={lazy(() => import(`./userdetails/UserDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/projectdetails/:id`}
          component={lazy(() => import(`./projectdetails/ProjectDetails`))}
        />
        {/* Modification end - 08.23.2022 - ympshipyard */}
        {/* Modification start - 16.09.2922 - ympolain-177 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/shipyarprofile/:id`}
          component={lazy(() => import(`./shipyardprofile/ShipyardProfile`))}
        />
        {/* Modification end - 16.09.2922 - ympolain-177 */}
        {/* Modification start - 07.09.2022 - ympolain-165 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/boatadminforms`}
          component={lazy(() => import(`./boatadminforms/BoatAdminForms`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/boatadminforms/:id`}
          component={lazy(() => import(`./boatformdetails/BoatFormDetails`))}
        />
        {/* Modification start - 07.09.2022 - ympolain-165 */}
        {/* Modification start - 09.30.2022 - ymploki-197 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/sections`}
          component={lazy(() => import(`./sections/Sections`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/orders`}
          component={lazy(() => import(`./orders/Orders`))}
        />
        {/* Modification end - 09.30.2022 - ymploki-197 */}
        {/* modification start - 10.19.2022 - great-galing-sc-255 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/archivehistory/:id`}
          component={lazy(() => import(`./archivehistory/ArchiveHistory`))}
        />
        {/* modification start - 10.19.2022 - great-galing-sc-255 */}
        {/* modification start - 10.31.2022 - loki-ribero-sc-277*/}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/log`}
          component={lazy(() => import(`./log/Log`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/officiallogbook/:id`}
          component={lazy(() => import(`./officiallogbook/OfficialLogbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/chiefofficer/:id`}
          component={lazy(() => import(`./chiefofficer/ChiefOfficer`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/garbagerecord/:id`}
          component={lazy(() => import(`./garbagerecord/GarbageRecord`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/gmdss/:id`}
          component={lazy(() => import(`./gmdss/Gmdss`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/oilrecord/:id`}
          component={lazy(() => import(`./oilrecord/OilRecord`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/cayman/:id`}
          component={lazy(() => import(`./cayman/Cayman`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/createlogbook/:id`}
          component={lazy(() => import(`./createlogbook/CreateLogbook`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/officiallogbook/logdetails/:id`}
          component={lazy(() =>
            import(`./officiallogbooklogdetails/OfficialLogbookLogDetails`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/chiefofficer/logdetails/:id`}
          component={lazy(() =>
            import(`./chiefofficerlogdetails/ChiefOfficerLogDetails`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/garbagerecord/logdetails/:id`}
          component={lazy(() =>
            import(`./garbagerecordlogdetails/GarbageRecordLogDetails`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/gmdss/logdetails/:id`}
          component={lazy(() => import(`./gmdsslogdetails/GmdssLogDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/oilrecord/logdetails/:id`}
          component={lazy(() =>
            import(`./oilrecordlogdetails/OilRecordLogDetails`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/cayman/logdetails/:id`}
          component={lazy(() => import(`./caymanlogdetails/CaymanLogDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/custom/logdetails/:id`}
          component={lazy(() => import(`./customlogdetails/CustomLogDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/custom/logbook/:id`}
          component={lazy(() => import(`./customlogbook/CustomLogbook`))}
        />
        {/* modification end - 10.31.2022 - loki-ribero-sc-277*/}
        {/* modification start - 12.16.2022 - loki-ribero-sc-277*/}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting`}
          component={lazy(() => import(`./accounting/Accounting`))}
        />
        {/* modification end - 12.16.2022 - loki-ribero-sc-277*/}
        {/* modification start - 10.01.2023 - loki-ribero-sc-277*/}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting/accountingreport`}
          component={lazy(() => import(`./accountingreport/AccountingReport`))}
        />
        {/* modification end - 10.01.2023 - loki-ribero-sc-277*/}
        {/* Modification start - 20.02.2023 - olain-tarcina-sc-346*/}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/hoursofrest/timesheet`}
          component={lazy(() => import(`./timesheet/Timesheet`))}
        />
        {/* Modification end - 20.02.2023 - olain-tarcina-sc-346*/}
        {/* Modification start - 04.27.2023 - maco-magsael-sc-407*/}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/terms`}
          component={lazy(() => import(`./terms/Terms`))}
        />
        {/* Modification end - 04.27.2023 - maco-magsael-sc-407*/}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/boatspareparts`}
          component={lazy(() => import(`./boatspareparts/BoatSpareParts`))}
        />
        {/* Modification start - 07.19.2022 - maco-magsael-sc-447 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/crewemail`}
          component={lazy(() => import(`./crewemail/CrewEmail`))}
        />
        {/* Modification start - 07.19.2022 - maco-magsael-sc-447 */}
        {/* Modification start - 03.07.2023 - olain-tarcina-sc-451 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/sales`}
          component={lazy(() => import(`./sales/Sales`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/sales/:id`}
          component={lazy(() => import(`./salesdetails/SalesDetails`))}
        />
        {/* Modification end - 12.07.2023 - olain-tarcina-sc-451 */}
        {/* Modification start - 07.31.2023 - management-module */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/management`}
          component={lazy(() => import(`./management/Management`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/management/:id`}
          component={lazy(() =>
            import(`./managementdetails/ManagementDetails`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/managementhome`}
          component={lazy(() => import(`./managementhome/ManagementHome`))}
        />
        {/* Modification end - 07.31.2023 - management-module */}
        {/* // Modification start - 09.20.2023 - maco-magsael-sc-485 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/equipmentphotos`}
          component={lazy(() => import(`./equipmentphotos/EquipmentPhotos`))}
        />
        {/* // Modification end - 09.20.2023 - maco-magsael-sc-485 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/change-password`}
          component={lazy(() => import(`./changepassword/ChangePassword`))}
        />
        {/* // Modification start - 16.11.2023 - olain-tarcina-sc-543 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/sales/clients/:id`}
          component={lazy(() => import(`./clientdetails/ClientDetails`))}
        />
        {/* // Modification end - 16.11.2023 - olain-tarcina-sc-543 */}
        {/* // modification start - 12.07.2023 - maco-magsael-sc-551 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting/accountinginvoice/:id`}
          component={lazy(() =>
            import(`./accountinginvoice/AccountingInvoice`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting/invoicedetails/:id`}
          component={lazy(() => import(`./invoicedetails/InvoiceDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting/invoiceedit/:id`}
          component={lazy(() => import(`./invoiceedit/InvoiceEdit`))}
        />
        {/* // modification end - 12.07.2023 - maco-magsael-sc-551 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/chat`}
          component={lazy(() => import(`./chat/Chat`))}
        />
        {/* // modification start - 03.06.2024 - maco-magsael-sc-612 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/equipment/:id`}
          component={lazy(() => import(`./jobequipment/JobEquipment`))}
        />
        {/* // modification end - 03.06.2024 - maco-magsael-sc-612 */}
        {/* // Modification start - 04.24.2024 - new-shipyard */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/shipyard/shipyarddetails/:id`}
          component={lazy(() => import(`./shipyarddetails/ShipyardDetails`))}
        />
        {/* // modification start - 08.06.2024 - maco-magsael-sc-616 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/reports/reportsemail`}
          component={lazy(() => import(`./reportsemail/ReportsEmail`))}
        />
        {/* // modification end - 08.06.2024 - maco-magsael-sc-616 */}
        {/* // Modification end - 04.24.2024 - new-shipyard */}
        {/* // modification start - 08.01.2024 - maco-magsael-sc-670 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/salescalendar`}
          component={lazy(() => import(`./salescalendar/SalesCalendar`))}
        />
        {/* // modification end - 08.01.2024 - maco-magsael-sc-670 */}
        {/* Modification start - 19.08.2024 - olain-tarcina-sc-664 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/hoursofrest/timesheet/:id`}
          component={lazy(() =>
            import(`./timesheetdetails/TimesheetDetails.js`)
          )}
        />
        {/* Modification end - 19.08.2024 - olain-tarcina-sc-664 */}
        {/* // Modification start - 08.22.2024 - nmaco-magsael-sc-677 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/liteymp`}
          component={lazy(() => import(`./liteymp/LiteYmp`))}
        />

        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/liteympequipment`}
          component={lazy(() => import(`./liteympequipment/LiteYmpEquipment`))}
        />

        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/liteympjob`}
          component={lazy(() => import(`./liteympjob/LiteYmpJob`))}
        />

        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/liteympjob/details/:id`}
          component={lazy(() =>
            import(`./liteympjobdetails/LiteYmpJobDetails`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/liteympequipment/details/:id`}
          component={lazy(() =>
            import(`./liteequipmentdetails/LiteEquipmentDetails`)
          )}
        />

        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/liteympboat/:id`}
          component={lazy(() => import(`./liteympboat/LiteYmpBoatDetails`))}
        />
        {/* // Modification end - 08.22.2024 - nmaco-magsael-sc-677 */}

        {/* // Modification start - 09.22.2024 - maco-magsael-sc-689 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/charter`}
          component={lazy(() => import(`./charter/Charter`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/charter/details/:id`}
          component={lazy(() => import(`./charterdetails/CharterDetails`))}
        />

        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/charter/allowance/:id`}
          component={lazy(() => import(`./allowance/Allowance`))}
        />

        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/charter/calendar/:id`}
          component={lazy(() => import(`./chartercalendar/CharterCalendar`))}
        />
        {/* // Modification end - 09.22.2024 - maco-magsael-sc-689 */}
        {/* // Modification start - 09.26.2024 - maco-magsael-sc-691 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/tyssales`}
          component={lazy(() => import(`./tyssales/TysSales`))}
        />

        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/tyssalesdetails/:id`}
          component={lazy(() => import(`./tyssalesdetails/TysSalesDetails`))}
        />

        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/tyssales/clients/:id`}
          component={lazy(() => import(`./tysclientdetails/TysClientDetails`))}
        />

        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/tyssales/calendar`}
          component={lazy(() => import(`./tyssalescalendar/TysSalesCalendar`))}
        />
        {/* // Modification end - 09.26.2024 - maco-magsael-sc-691 */}
        {/* // modification start - 10.07.2024 - maco-magsael-sc-692 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/employees/payroll`}
          component={lazy(() => import(`./payroll/Payroll`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/employees/payroll/details/:id`}
          component={lazy(() => import(`./payrolldetails/PayrollDetails`))}
        />
        {/* // modification end - 10.07.2024 - maco-magsael-sc-692 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/log/archive`}
          component={lazy(() => import(`./logarchive/LogArchive`))}
        />
        {/* // Modification start - 16.01.2025 - olain-tarcina-sc-721 */}
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/vesseldocs/manage-checklists`}
          component={lazy(() =>
            import(`./vesseldocschecklists/VesseldocsChecklists`)
          )}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/vesseldocs/checklist/:id`}
          component={lazy(() => import(`./checklistdetails/ChecklistDetails`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/vesseldocs/manage-checklist/:id`}
          component={lazy(() =>
            import(`./vesseldocschecklistdetails/VesseldocsChecklistDetails`)
          )}
        />
        {/* // Modification end - 16.01.2025 - olain-tarcina-sc-721 */}
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
