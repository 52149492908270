import {
  GET_PROGRAM,
  SHOW_PROGRAM_LOADING,
  PROGRAM_ERROR,
  GET_PROGRAM_DETAILS,
  SHOW_EDIT_PROGRAM_LOADING,
  EDIT_PROGRAM,
  DELETE_PROGRAM,
  CREATE_PROGRAM,
} from '../actions/types';

/**
 * maco-magsael-sc-754: Shipyard - create interface to manage shipyard projects

 */

const initialState = {
  programlist: [],
  programID: null,
  programDetails: null,
  programLoading: false,
  editProgramLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_PROGRAM_LOADING:
      return {
        ...state,
        programLoading: true,
      };
    case SHOW_EDIT_PROGRAM_LOADING:
      return {
        ...state,
        editProgramLoading: true,
      };
    case GET_PROGRAM:
      return {
        ...state,
        programlist: payload,
        programLoading: false,
      };
    case CREATE_PROGRAM:
      return {
        ...state,
        programlist: [payload, ...state.programlist],
        editProgramLoading: false,
      };
    case EDIT_PROGRAM:
      return {
        ...state,
        programlist: state.programlist.map((team) =>
          team._id === payload._id
            ? {
                ...team,
                name: payload.name,
              }
            : team
        ),
        editProgramLoading: false,
      };
    case GET_PROGRAM_DETAILS:
      return {
        ...state,
        programDetails: payload,
      };
    case DELETE_PROGRAM:
      return {
        ...state,
        programlist: state.programlist.filter((team) => team._id !== payload),
        editProgramLoading: false,
        // modification start - 09.12.2022 - galing-173
        programLoading: false,
        // modification end - 09.12.2022 - galing-173
      };
    case PROGRAM_ERROR:
      return {
        ...state,
        error: payload,
        programLoading: false,
      };
    default:
      return state;
  }
}
